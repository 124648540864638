import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_about.module.scss"

import irikura from "src/images/irikura-headshot.jpg"
import shibata from "src/images/shibata.jpg"
import tokamachi from "src/images/tokamachi-front.jpg"
import nagaoka from "src/images/nagaoka-front.jpg"
import niigata from "src/images/recruit/lab-front2.jpg"
import Logo from "src/images/mirai-lab-logo.jpg"

const AboutPage = () => (
  <Layout>
    <Seo title="会社概要" />

    <div className="pageWrapper">
      <div className="pageTtl">
        <h1>会社概要</h1>
      </div>
      <div className={styles.aboutMsgWrapper}>
        <div>
          <p className={styles.aboutRenk}><span>"Re"</span>NK CHANNEL とは</p>
          <p className={styles.aboutMsg}>
            ハードオフグループが掲げる店舗とお客様、そして働く社員とスタッフを繋ぐシステムを創り上げるため各チャネル間での繋がりを強化し、
            それぞれのチャネルへの流入を増加させることが
            <span> 「”Re”NK CHANNEL構想」</span>
            その実現のため誕生したのがリンクチャネル株式会社です。
            わたしたちは、システムを通じて利用する方に寄り添い新しい未来を創りたいという思いを持ち
            開発や運営保守を行ってまいります。
          </p>
        </div>
        <div className={styles.headshot}>
          <img src={ irikura } alt="代表取締役社長 入倉孝行" />
          <p>代表取締役社長 入倉孝行</p>
        </div>
      </div>

      <div className={styles.companyDetailsWrapper}>
        <table className={styles.companyDetails}>
          <thead>
            <tr>
              <th>会社名</th>
              <td>リンクチャネル株式会社<br />（ReNK CHANNEL Co., Ltd.）</td>
              <td className={styles.empty}></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>設立年月日</th>
              <td>1997年7月1日</td>
              <td className={styles.empty}></td>
            </tr>
            <tr>
              <th>沿革</th>
              <td colSpan={2} className={styles.history}>
                <ul>
                  <li>
                    <span className={styles.year}>1997年7月1日</span>
                    <span className={styles.detail}>インフォノース株式会社を設立</span>
                  </li>
                  <li>
                    <span className={styles.year}>2000年9月</span>
                    <span className={styles.detail}>長岡営業所開設。</span>
                  </li>
                  <li>
                    <span className={styles.year}>2004年4月</span>
                    <span className={styles.detail}>ハードオフコーポレーションのボックスショップ事業で取引を開始。</span>
                  </li>
                  <li>
                    <span className={styles.year}>2020年4月1日</span>
                    <span className={styles.detail}>リンクチャネル株式会社へ社名変更。</span>
                  </li>
                  <li>
                    <span className={styles.year}>2020年11月</span>
                    <span className={styles.detail}>ハードオフ未来ラボ稼働開始。</span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>役員</th>
              <td>
                代表取締役社長<span>入倉 孝行</span><br />
                取締役<span>内山 春彦</span><br />
                取締役<span>白神 雄一郎</span><br />
                取締役<span>遠藤 利行</span><br />
                監査役<span>鍋谷 佳人</span>
              </td>
              <td className={styles.empty}></td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>
                本社 <br />
                〒957-0063<br />
                新潟県新発田市新栄町3丁目1番13号<br />
                <a href="https://goo.gl/maps/dCa74KLryG9KkiEn7" target="_blank" rel="noopener noreferrer">Google Mapを開く</a>
                <br />
                TEL: 0254-24-4344
              </td>
              <td className={styles.officeImg}>
                <img src={ shibata } alt="本社" />
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                十日町開発センター<br />
                〒948-0075<br />
                新潟県十日町市丸山町6番地11<br />
                <a href="https://goo.gl/maps/tuSaxi7GqNFAgaxbA" target="_blank" rel="noopener noreferrer">Google Mapを開く</a>
                <br />
                TEL: 025-752-0801
              </td>
              <td className={styles.officeImg}>
                <img src={ tokamachi } alt="十日町開発センター" />
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                長岡CSセンター<br />
                〒940-0029<br />
                新潟県長岡市東蔵王2丁目6番20号吉沢ビル2F<br />
                <a href="https://goo.gl/maps/VQkJHZL56RHjTFM68" target="_blank" rel="noopener noreferrer">Google Mapを開く</a>
                <br />
                TEL: 0258-25-6310<br />
                FAX: 0258-25-6309
              </td>
              <td className={styles.officeImg}>
                <img src={ nagaoka } alt="長岡CSセンター" />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <td>
                ハードオフ未来ラボ
                <img src={ Logo } alt="ハードオフ未来ラボロゴ" className={styles.labLogo} /> <br />
                〒950-0911<br />
                新潟県新潟市中央区笹口1丁目2番 PLAKA2ビル 2F<br />
                <a href="https://goo.gl/maps/PepxH38EJm1tmY6o6" target="_blank" rel="noopener noreferrer">Google Mapを開く</a>
              </td>
              <td className={styles.officeImg}>
                <img src={ niigata } alt="ハードオフ未来ラボ" />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </Layout>
)

export default AboutPage
