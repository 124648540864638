// extracted by mini-css-extract-plugin
export var aboutMsgWrapper = "_about-module--aboutMsgWrapper--fBZmo";
export var headshot = "_about-module--headshot--pgTJj";
export var aboutRenk = "_about-module--aboutRenk--qmZLx";
export var aboutMsg = "_about-module--aboutMsg--0pwHl";
export var companyDetailsWrapper = "_about-module--companyDetailsWrapper--7c4TU";
export var companyDetails = "_about-module--companyDetails--E503P";
export var history = "_about-module--history--ELVNp";
export var year = "_about-module--year--5cEdk";
export var detail = "_about-module--detail--logyC";
export var labLogo = "_about-module--labLogo--UhTM3";
export var officeImg = "_about-module--officeImg--OfzHm";
export var empty = "_about-module--empty--XIc8s";